<script setup lang="ts">

</script>

<template>
  <div class="input-group row full-width g-16 the-same-gap flex-start flexed">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">

</style>
